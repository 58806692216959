import { useMemo } from 'react';
import { ContestType } from '@betterpool/api-types/contests-service';

import { useContest } from './useContest';
import { useIsAnonymousPicksEnabled } from './useIsAnonymousPicksEnabled';

export const useIsAnonymousPicksAllowedForContest = (contestId: string) => {
  const fullContest = useContest(contestId);
  const isAnonymousPicksEnabled = useIsAnonymousPicksEnabled();

  const isAnonymousPicksAllowedForContest = useMemo(
    () =>
      [
        ContestType.TEAM_SURVIVOR,
        ContestType.TEAM_PICK_EM,
        ContestType.PLAYER_TIER,
        ContestType.PLAYER_ONE_AND_DONE,
      ].includes(fullContest?.data?.contest_type),
    [fullContest?.data?.contest_type]
  );

  return {
    fullContest,
    isAnonymousPicksEnabled,
    isAnonymousPicksAllowedForContest,
  };
};
