export default {
  actions: {
    copyLink: 'Copy Link',
    returnToContestDetails: 'Return to Contest Details',
  },
  stats: {
    entries: 'Entries',
    entry: 'Entry',
    prizes: 'Prizes',
  },
  contestType: {
    multientry: 'Multi Entry',
    singleentry: 'Single Entry',
    topx: 'Top X',
    multiplier: 'Multiplier',
    team_survivor: 'Team Survivor',
    team_pick_em: "Team Pick'Em",
    undefined: 'Unknown',
  },
  experienceLevel: {
    experienced: {
      title: 'Experienced user',
      description:
        'A user who has played in 50 or more public or private contests on Splash Sports.',
    },
    highly_experienced: {
      title: 'Highly experienced user',
      description:
        'A user who has <li>played in 500 or more public or private contests on Splash Sports</li><li>won $1,000 or more in 3 or more contests</li><li>won at least $2,500 in total on Splash Sports</li>',
    },
  },
  enterContestModal: {
    enterContest: {
      splashHostedMigrationMessage:
        'To provide you with the best tools and experience, all {{templateName}} contests are now hosted exclusively on our Splash platform.',
      title: 'Add entries',
      text: 'How many entries would you like to enter into this contest?',
      balance: 'Balance: {{balance}}',
      maxEntries__hasPlural: {
        one: '{{count}} max entry',
        other: '{{count}} max entries',
      },
      addEntriesEntertainmentOnly__hasPlural: {
        one: 'Add {{count}} entry',
        other: 'Add {{count}} entries',
      },
      depositToEnter: 'Deposit to enter',
      addEntries__hasPlural: {
        one: 'Add {{count}} entry: {{entryFee}}',
        other: 'Add {{count}} entries: {{entryFee}}',
      },
      close: 'Close',
    },
    success: {
      title: 'Entry successful',
      text__hasPlural: {
        one: 'You have added {{count}} entry into this contest',
        other: 'You have added {{count}} entries into this contest',
      },
      balance: 'Your balance is now <balance>{{balance}}</balance>',
      redirecting: 'Redirecting to the contest in {{seconds}}',
    },
  },
  enterContestError: {
    full: 'The contest is full',
    over: 'The contest has ended',
    cancel: 'The contest has been cancelled',
    inprogress: 'The contest has already started',
    default: 'You are not allowed to Create or Join entry',
    deadline: 'The contest deadline has passed',
    maxEntries: 'Max entries reached',
    locationLoading: 'Checking your location',
    locationInvalid: `Contest Type is not allowed in your location`,
    userInvalid: `Please verify your information before entering a contest`,
  },
  contestCard: {
    footer: {
      mySurvivors: 'My survivors',
      survivors: 'Survivors',
      myEntries: 'My entries',
      entries: 'Entries',
      entry: 'Entry',
      winning: 'Winning',
      won: 'Won',
      prizes: 'Prizes',
      segmented: 'Won (to date)',
      myRank: 'My rank',
    },
    banner: {
      makePicks: 'Make your pick(s)',
      draftLineup__hasPlural: {
        one: '{{count}} undrafted lineup',
        other: '{{count}} undrafted lineups',
      },
      lineupWithInjuries__hasPlural: {
        one: '{{count}} lineup with injuries',
        other: '{{count}} lineups with injuries',
      },
      picksheetNotAvailable: {
        teamBased: 'Pick sheet not yet available',
        playerBased: 'Player pool not yet available',
      },
    },
  },
  cardActions: {
    inviteManager: 'Manage invites',
    massCommunication: 'Send post',
    entrantManager: 'Manage entrants',
  },
  userStatusModals: {
    common: {
      explanation:
        'Why does Splash need my location? <link>View location sharing instructions</link>',
      contactSupport:
        'If you believe you are in a permitted location, you can try again or <link>contact support</link>',
      tryAgainCta: 'Try again',
      recheckLocationCta: 'Recheck location',
      dismissCta: 'Dismiss',
      contactSupportCta: 'Contact support',
    },
    userNotVerifiedModal: {
      title: 'You must be verified',
      text: 'Please complete identity verification on your account to {{desiredAction}}.',
      desiredActions: {
        makeEntries: 'make entries into this contest',
        deposit: 'deposit funds into your account',
        withdraw: 'withdraw funds from your account',
      },
      verifyNow: 'Verify now',
      dismiss: 'Dismiss',
    },
    verifyingDocumentsModal: {
      title: 'Verifying documents',
      text: 'Your documents are in the process of being verified. Try again later.',
      dismiss: 'Dismiss',
      contactSupport: 'Contact support',
    },
    locationPermissionRequired: {
      title: 'Location permission required',
      text: 'To use Splash, your must enable location permission in the settings of your browser.',
      cta: 'Recheck permissions',
    },
    invalidLocation: {
      title: 'Invalid location',
      text: `Some locations don't allow play on Splash. Please make sure you are in a permitted location, then try again. <link>View permitted locations</link>`,
    },
    locationError: {
      title: 'Location error',
      text: 'There was an issue verifying your location.',
    },
    locationRestriction: {
      title: 'Location restriction',
      text: 'Your current location ({{detectedLocation}}) prevents you from {{desiredAction}}. Check our map to view where you can play, or go back to the lobby to view more contests. <link>View permitted locations</link>',
      note: '<bold>You are able to continue making picks in the contests that you currently have entries in.</bold>',
    },
    ageRestriction: {
      title: 'Age restriction',
      text: 'Some jurisdictions have different age requirements. Please make sure you are in a permitted location, then try again. <link>View permitted locations</link>',
    },
  },
};
